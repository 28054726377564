@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;500;700&display=swap');

:root{
  --civis-green: #8BB02D;
  --civis-green-dark: rgb(77, 99, 23);
  --civis-green-dark-20: rgb(77, 99, 23, 0.2);
  --civis-green-30: rgba(139, 176, 45, 0.3);
}

*{
  scroll-behavior: smooth;
}

.main{max-width: 100vw;
overflow: hidden;}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FAFAFA;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h3{
  text-align: center;
  color: var(--civis-green);
}


h1, h2, h3, h4, h5, h6, p{
  font-family: 'Roboto', sans-serif;
}

p{
  font-size: 1.2rem;
}

p, h3{font-weight: 300;}


h3{font-size: 1.4rem; margin-top: 3rem;}

.container{
  max-width: 1000px;
}
h1{
  color: var(--civis-green);
  font-size: 4rem;
  display: block;
  margin-bottom: 50px;
}
@media screen and (max-width: 768px) {
  p{
    text-align: justify;
  }
  
  h1{
    font-size: 2.5rem;
  }
}

a.about_button, input[type="submit"].about_button{
  color: var(--civis-green);
  border: 1px solid var(--civis-green-30);
  border-radius:50px;
  padding: 5px 20px;
  text-decoration: none;
  font-size: 1rem;
  transition: 1s;
  font-weight: 500;
  margin: 15px 0px;

}

a.about_button:hover, input[type="submit"].about_button {
  background-color: var(--civis-green);
  color: white;
}


input[type="submit"].about_button:hover{
  background-color: var(--civis-green-dark);
}