.hamburger {
    margin: 1em;
    width: 40px;
    z-index: 55;
  }

  .hamburger:hover{
      cursor: pointer;
  }
  
  .hamburger:after, 
  .hamburger:before, 
  .hamburger div {
    background-color: #fff;
    border-radius: 3px;
    content: '';
    display: block;
    height: 5px;
    margin: 7px 0;
    transition: all .2s ease-in-out;
  }

  .x-hamburger:before {
    transform: translateY(12px) rotate(135deg);
  }
  
  .x-hamburger:after {
    transform: translateY(-12px) rotate(-135deg);
  }
  
  .x-hamburger div {
    transform: scale(0);
  }