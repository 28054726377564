#menu-bg{
    background-color: rgb(29, 88, 5);
    background-image: url('bg1.png');
    background-size: cover;
    width: 100%;
    height: 80px;
    position: fixed;
    z-index: 10;
}

#contact-holder{
    padding-top: 130px;
    min-height: 80vh;
    padding-bottom: 50px;
}

.input-holder{
    padding-bottom: 50px;
}

.input-holder label{
    color: var(--civis-green-dark);
}

.input-holder textarea{
    min-height: 150px;
    max-height: 150px;
}

.input-holder input, .input-holder textarea{
    display: block;
    width: 100%;
    min-width: 200px;
    margin-bottom: 20px;
    border: none;
    background-color: var(--civis-green-dark-20);
    padding: 5px 10px;
    border-radius: 5px;
}

.input-holder input{
    max-width: 300px;
}

.google-map{
    width:100%;
    height:50%;
    min-height: 200px;
}

.classic-contact{
    margin-top: 50px;
    color: var(--civis-green-dark);
}

.classic-contact a {
    color: var(--civis-green-dark);
}

.classic-contact img{
    width: 1rem;
}

.classic-contact td{
    padding: 10px 5px;
}

.input-holder input[type="submit"]{
    background-color: white;
    float: right;
    width: 130px;
}