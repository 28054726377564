#hero{
    min-height: 100vh;
    width: 100%;
    background-color: lightgray;
    background-image: url('hero_img.png');
    background-size: cover;
    background-position: center;
}

#hero{
    display: flex;
    justify-content:center;
    align-items: center;
    /* position: relative; */

}

#hero h1,#hero h2{
    text-transform: uppercase;
    text-align: center;
    color: white;
    text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
}

#hero h1{
    font-size: 5rem;
    margin-bottom: 0px;
}

#hero h2{
    font-size: 4rem;
    font-weight: 300;
}

#hero_title{z-index: 2;}

#hero_title a{
    background-color: rgba(0, 0, 0, 0.3);
    display: block;
    width: 200px;
    margin: auto;
    text-align: center;
    padding: 10px;
    color: white;
    text-decoration: none;
    border-radius:50px;
    border: 2px solid white;
    font-size: 1.5rem;
    transition: 0.5s;
    font-weight: 300;
    margin-top: 40px;
}

#hero_title a:hover{
    background-color: rgba(0, 0, 0, 0.5);
}

#arrow_down{
    position: absolute;
    bottom: 0;
    z-index: 2;
}

#arrow_down img{
    width: 60px;
    transition: 0.5s;
    filter: invert(1)
}

#arrow_down img:hover{
    width: 65px;
}