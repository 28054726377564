#menu-bg{
    background-color: rgb(29, 88, 5);
    background-image: url('bg1.png');
    background-size: cover;
    width: 100%;
    height: 80px;
    position: fixed;
    z-index: 10;

}

#products{
    padding-top: 180px;
    width: 100%;
    min-height: 100vh;
    color: black;
}

.filters{
    font-weight: 300;
}

.filters label{
    margin-left: 5px;
    margin-bottom:10px;

}

.filters h2{
    color: var(--civis-green);
    font-size: 1.5rem;
}


.cards_holder h2{
    color: var(--civis-green);
}

.cards_holder hr{
    height: 3px;
    background-color: rgb(18, 53, 4);
}


.peacard{
    width: 95%;
    margin: auto;
    height: 300px;
    border-radius: 10px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
    overflow: hidden;
    position: relative;
    margin-bottom: 40px;
    padding-bottom: 50px;
}

.peacard h3{
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 500;
    text-align: left;
    padding: 10px;
    margin-top: 0;
}
.peacard button{
    position: absolute;
    bottom: 10px;
    right: 10px;
    padding: 5px;
    font-size: 1.2rem;
    border-radius: 5px;
    border: 1px solid var(--civis-green);
    background-color: transparent;
    font-weight: 500;
    transition: 0.5s;
    color: var(--civis-green);
}

.peacard button:hover{
    background-color: var(--civis-green);
    color: white;
}

.peacard_image{
    width: 100%;
    height: 150px;
    background-color: darkgray;
    border-radius: 10px;
}

.peacard_image div{
    width: 40px;
    height:40px;
    background-color: var(--civis-green-dark);
    line-height: 40px;
    text-align: center;
    color: white;
    border-radius: 10px;
}

@media screen and (max-width: 576px) {
    .peacard{
        min-height: 200px;
        height: auto;
    }
}
