footer{
    width: 100%;
    min-height: 40vh;
    background-color: rgb(18, 53, 4);
    color: white;
    position: relative;
    padding-bottom: 50px;;
}

footer h3{
    text-transform: uppercase;
}

.footerLogo{
    width: 80%;
    max-width: 300px;
    display:block;
    margin: 20px auto;
    margin-top: 50px;
}

.copyright{
    position: absolute;
    bottom: 10px;
    color: rgba(255, 255, 255, 0.5);
}

footer a{
    color: white;
    font-weight: 300;
    display: block;
    margin-bottom: 15px;
}

footer a:hover{
    color: white;
    font-weight: 500;
}

footer a>img{
    width: 1rem;
    margin-right: 5px;
}

@media screen and (max-width: 768px) {
    footer a{
        text-align: center;
    }
  }

.center{
    text-align: center;
    font-size: 0.8rem;
}