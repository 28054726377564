/*DESKTOP KINÉZET*/
#menu{
    width: 100%;
    height: 80px;
    position: fixed;
    z-index: 50;
    transition: 0.5s;
}

#logo{
    height: 65px;
    z-index: 55;
    position: relative;
}

#logo:hover{
    cursor: pointer;
}

.darkbg{
    background-color: rgba(0, 0, 0, 0.6);
}

.content{
    max-width: 1200px;
    width: 90%;
    margin: auto;
}

.menu_layout{
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#menubuttons{
    color: white;
    font-size: 1.5rem;
}

#menubuttons a{
    height: 30px;
    padding: 15px;
    font-size: 20px;
    text-decoration: none;
    color: white;
    transition: 5s;
    font-weight: 300;
}

#menubuttons a:hover{
    text-decoration: underline;
}

#menubuttons button img {
    position: absolute;
    width: 40px;
    border-radius: 50%;
    top: 50%;
    transform: translate(0%, -50%);
    border: 3px solid white;
}

#menubuttons button{
    background: transparent;
    border: none;
    margin-left: 10px;
}

/*MOBILOS KINÉZET*/
#mobile-menu{
    width: 100vw;
    padding-left: 4%;
    margin-left: -1px;
    height: 80px;
    position: fixed;
    left: 50%;
    transform: translate(-50%);
    z-index: 80;
    transition: 0.5s;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mobile-side-nav{
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.452);
    position: fixed;
    left: 120%;
    top: 0;
    z-index: 20;
    transition: 0.1s;
}

.side-nav-open{
    left: 0%;
}

.mobile-side-buttonholder{
    overflow: hidden;
    width: 50%;
    min-width: 300px;
    max-width: 100vw;
    height: 100vh;
    background-color: #3D3C3C;
    float: right;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mobile-side-buttonholder div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mobile-side-buttonholder a{
    color: var(--civis-green);
    font-weight: 300;
    text-decoration: none;
    font-size: 1.5rem;
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--civis-green);
    transition: 0.2s;
}

.mobile-side-buttonholder a:hover{
    color: white;
}

.mobile-side-buttonholder div button{
    margin-top: 20px;
    background: transparent;
    border: none;
}

.mobile-side-buttonholder div button img{
     width: 50px;
    border-radius: 50%;

    transform: translate(0%, -50%);
    border: 3px solid white;
}

