#about{
    min-height: 100vh;
    height: auto;
    position: relative;
}

.padding-100-topbottom{
    padding-bottom: 100px;
    padding-top: 100px;
}



.about_card{
    width: 90%;
    height: 330px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    margin: auto;
    margin-top: 20px;
    border-radius: 20px;
    position: relative;
}

.about_card a {
    position: absolute;
    /* padding: 5px 20px; */
    /* color: var(--civis-green); */
    /* text-decoration: none; */
    /* border-radius:50px; */
    /* border: 1px solid var(--civis-green-30); */
    /* font-size: 1rem; */
    /* transition: 1s; */
    /* font-weight: 500; */
    /* margin-bottom: 15px;; */
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }
  
  .about_card a:hover {
    background-color: var(--civis-green);
    color: white;
  }

.card-img{
    width: 100%;
    height: 180px;
    background-color: lightgray;
    border-radius: 20px;
    background-size: cover;
    background-position: center;
}


.card-text{
    width: 90%;
    margin: auto;
    margin-top: 10px;
    text-align: center;
    font-weight: 300;
}

.mobileCard{
    height: 200px;
    width: 90%;
    border-radius: 10px;
    margin: 20px auto;
    background-size: cover;
    background-position: center;
    overflow: hidden;
}

.mobileCard div{
    width: 100%;
    height: 200px;
    background-color: rgba(0, 0, 0, 0.5);
    position: relative;
}

.mobileCard div span{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    color: white;
    text-align: center;
    font-size: 1.3rem;
    font-weight: 700;
}