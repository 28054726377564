.bg_section{
    background-color: rgb(18, 53, 4);
    background-size: cover;
    background-position: left;
    min-height: 50vh;
    width: 100%;
    display: block;
}

.bg_color_filter{
    width:100%;
    min-height: 50vh;
    background: 
    linear-gradient(90deg, rgba(18, 53, 4, 0.2) 0%, 
    rgba(18, 53, 4, 0.7) 20.69%,
    rgba(18, 53, 4) 100%);
}

.dark_bg p{
    color: white;
}

.bg_section h3{
    text-align: left;
    margin-bottom: 2rem;
}

.wider{
    max-width: 1200px !important;
    display: flex;
    align-items: center;
    vertical-align: middle;
}


.wider div{
    position: relative;
}
.extra_picture_holder{
    display: flex;
    align-items: center;

}
.extra_picture{
    width: 90%;
    margin: auto;
    display: block;
}

ul li a {
    color: black;
    font-weight: 300;
}

ul li a:hover {
    color: var(--civis-green);
    font-weight: 300;
}

.layered_image{
    width: 25vw;
    height: 15vw;
    min-width: 300px;
    min-height: 200px;
    background-size: cover;
    /* border-radius: 20px; */
    border: 10px solid white;
    margin-left: 20%;
}

.layered_image:nth-child(2){
    margin-left: 50%;
    margin-top: -50px;
}

.layered_image:nth-child(3){
    margin-top: -50px;
}

@media screen and (max-width: 992px) {
    .hide_on_phone{
        display: none;
    }

    .bg_color_filter{
        padding-top: 100px;
        padding-bottom: 100px;
        width:100%;
        min-height: 50vh;
        background: none !important;
        background-color: rgba(18, 53, 4, 0.849) !important;
    }

    .layered_image{

        margin-left: 10%;}
    .layered_image:nth-child(2){
        margin-left: 20%;
    }
}
@media screen and (max-width: 768px) {
    .hide-on-small{display: none;}
}